import { AppStoreButton } from './AppStoreButton';
import pastel from './eclipsepastel.webp';
import { Header } from './Header';

export function Home() {
    

    return (
        <>
        <Header />
        <div className='App-body'>
            <AppStoreButton />
            <p className='Get-app'>
                Get the Free EclipseGo app to get started.
            </p>
            <img src={pastel} className='Pastel' alt="friends at eclipse" />
        </div>
        <footer>
          <p className='Footer'>
            © 2024 Garafa, LLC
          </p>
          <a href='/privacy.html'>Privacy Policy</a>
        </footer>
      </>
    )
}