import { useEffect } from "react";
import { Header } from "./Header";
import { AppStoreButton } from "./AppStoreButton";
import MediaQuery from 'react-responsive'
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";

export function Eclipse() {
    const appID = "6479233677";
    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = "apple-itunes-app";
        meta.content = `app-id=${appID}`;
        document.getElementsByTagName('head')[0].appendChild(meta);
        return () => {
            document.getElementsByTagName('head')[0].removeChild(meta);
        };
    }, [appID]);

    const location = useLocation();
    const currentUrl = window.location.origin + location.pathname + location.search;
    const appUrlScheme = `com.garafa.eclipsego://ecl${location.search}`;
  
    return (
        <>
        <Header />
        <div>
            <p className='Get-app'>
                How to view the eclipse details for the shared location:
            </p>
            <p className='Step-text'>
                Step 1: 
            </p>
            <p className='Step-instructions'>
                Get the free EclipseGo app.
            </p>
            <AppStoreButton />
            <p className='Step-text'>
                Step 2: 
            </p>
            <p className='Step-instructions'>
                Tap this link on your mobile device:
            </p>
            <a className='URL-scheme-link' href={appUrlScheme}>Open in the EclipseGo App</a>
            <MediaQuery minWidth={1224}>
                <p className='Get-app'>
                    Scan to open this page on your phone.
                </p>
                <QRCode
                    size={256}
                    value={currentUrl}
                    viewBox={`0 0 256 256`}
                />
            </MediaQuery>
        </div>
      </>
    );
  }