import React from 'react';
import './App.css';
import './AppStoreButton.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Eclipse } from './Eclipse';
import { Home } from './Home';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/ecl" element={<Eclipse />} />
          <Route path="/" element={ <Home />
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
