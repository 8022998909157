import appStore from './AppStore.svg';
import googlePlay from './google-play-badge.png';
import './AppStoreButton.css';

export function AppStoreButton() {
  const openiOS = () => {
    window.open('https://apps.apple.com/app/id6479233677', '_blank', 'noopener,noreferrer');
  };

  const openPlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.garafa.EclipseGo', '_blank', 'noopener,noreferrer');
  }

  return (
    <div>
      <button onClick={openiOS} className='Store-Button'>
        <img src={appStore} className='App-Store-Image' alt="Download on the App Store" />
      </button>
      <button onClick={openPlay} className='Store-Button'>
        <img src={googlePlay} className='Play-Store-Image' alt="Get on Goolge Play Store" />
      </button>
    </div>
  );
}